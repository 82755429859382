import {
  CREATE_SAUCE,
  GET_SAUCES,
  UPDATE_SAUCE,
  DELETE_SAUCE,
} from "../actions/sauces.actions";

const initialState = {};

export default function menusReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_SAUCE:
      return action.payload;
    case GET_SAUCES:
      return action.payload;
    case UPDATE_SAUCE:
      return state.map((sauce) => {
        if (sauce.id === action.payload.id) {
          return {
            ...sauce,
            nom: action.payload.nom,
            showed: action.payload.showed,
          };
        } else return sauce;
      });
    case DELETE_SAUCE:
      return state.filter((sauce) => sauce.id !== action.payload.id);
    default:
      return state;
  }
}
