import axios from "axios";
import api_url from "../Api";

// menus
export const CREATE_SAUCE = "CREATE_SAUCE";
export const GET_SAUCES = "GET_SAUCES";
export const UPDATE_SAUCE = "UPDATE_SAUCE";
export const DELETE_SAUCE = "DELETE_SAUCE";

export const createSauce = (nom) => {
  return (dispatch) => {
    return axios
      .post(`${api_url}api/sauces/`, {
        nom: nom,
      })
      .then((res) => {
        return axios
          .get(`${api_url}api/sauces/`)
          .then((res) => {
            dispatch({ type: CREATE_SAUCE, payload: res.data.item });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };
};

export const getSauces = () => {
  return (dispatch) => {
    return axios
      .get(`${api_url}api/sauces/`)
      .then((res) => {
        dispatch({ type: GET_SAUCES, payload: res.data.item });
      })
      .catch((err) => console.log(err));
  };
};

export const updateSauce = (id, nom, showed) => {
  return (dispatch) => {
    return axios
      .put(`${api_url}api/sauces/${id}`, {
        nom: nom,
        showed: showed,
      })
      .then((res) => {
        dispatch({
          type: UPDATE_SAUCE,
          payload: {
            id,
            nom,
            showed,
          },
        });
      })
      .catch((err) => console.log(err));
  };
};

export const deleteSauce = (id) => {
  return (dispatch) => {
    return axios
      .delete(`${api_url}api/sauces/${id}`)
      .then((res) => {
        dispatch({
          type: DELETE_SAUCE,
          payload: { id },
        });
      })
      .catch((err) => console.log(err));
  };
};
