import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import {DndContext, MouseSensor, TouchSensor, useDroppable, useSensor, useSensors} from '@dnd-kit/core';
import {restrictToVerticalAxis, restrictToParentElement} from '@dnd-kit/modifiers';
import {arrayMove, rectSortingStrategy, SortableContext} from '@dnd-kit/sortable';
import React, { useState } from "react";
import Menu from "./Menu";
import Sauces from "./Sauces";
import { useDispatch } from "react-redux";
import { updateRubriqueOrder } from "../actions/menus.actions";

export default function Rubrique({ id, rubrique, menus, variant }) {
  //On définit les items à trier
  const [items, setItems] = useState(menus);
  const { setNodeRef } = useDroppable({ id });
  const dispatch = useDispatch();

  //Les sensors servent à gérer les comportement au clique, au touch, etc
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
    useSensor(TouchSensor)
  );

  //On met les items dans le nouvel ordre
  const handleDragEnd = ({ active, over }) => {
    if (!over) {
      return;
    }

    const activeIndex = active.data.current.sortable.index;
    const overIndex = over.data.current?.sortable.index || 0;

    setItems((items) => {
      const newItems =  arrayMove(items, activeIndex, overIndex);
      dispatch(
        updateRubriqueOrder(
          id,
          newItems.map(item => item.id)
        )
      );
      return newItems;
    });
  };

  return (
    <Accordion className={variant}>
      <AccordionSummary
        className="summaryDrawer"
        aria-controls="panel1a-content"
        id="panel1a-header"
        expandIcon={<KeyboardArrowDownIcon className="icon" />}
      >
        <div className="rubriqueDrawer">
          {rubrique
            .split(" ")
            .map(function (word) {
              return word[0].toUpperCase() + word.substr(1);
            })
            .join(" ")}
        </div>
      </AccordionSummary>
      {rubrique === "Les Planchas" && (
        <p>
          <br />
          Servies avec des frites ou légumes verts ou écrasé de pommes de terre
          ou pâtes fraîches ou salade
        </p>
      )}
      <DndContext
        sensors={sensors}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        autoScroll={false}
      >
        <SortableContext id={id} items={items} strategy={rectSortingStrategy}>
           <div ref={setNodeRef}>
            {items.map(function (menu, index) {
              return <Menu menu={menu} key={index} />;
            })}
          </div>
        </SortableContext>
      </DndContext>     
      {rubrique === "Les Planchas" && <Sauces />}
    </Accordion>
  );
}
