import { GET_USER } from "../actions/user.actions";

const initialState = {};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER:
      return action.payload;
    // case UPDATE_MENU:
    //   return state.map((menu) => {
    //     if (menu.id === action.payload.id) {
    //       return {
    //         ...menu,
    //         message: action.payload.message,
    //       };
    //     } else return post;
    //   });
    // case DELETE_MENU:
    //   return state.filter((post) => post._id !== action.payload.postId);
    default:
      return state;
  }
}
