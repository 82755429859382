import { Button, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { createSauce } from "../../actions/sauces.actions";

export default function CreateSauce() {
  const dispatch = useDispatch();
  const [newName, setNewName] = useState(null);

  function handleCreate(e) {
    if (newName) {
      dispatch(createSauce(newName));
      setNewName(null);
      document.getElementById("standard-basic").value = null;
    }
  }

  return (
    <div className="createSauce">
      <TextField
        id="standard-basic"
        label="Ajouter une sauce"
        variant="standard"
        color="primary"
        fullWidth
        onChange={(e) => setNewName(e.target.value)}
      />
      <Button variant="contained" size="small" onClick={handleCreate}>
        Ajouter
      </Button>
    </div>
  );
}
