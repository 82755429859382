import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import React from "react";
import { deleteSauce } from "../../actions/sauces.actions";

export default function DeleteSauce({ sauce, setDeleting }) {
  const dispatch = useDispatch();

  function handleDelete(e) {
    dispatch(deleteSauce(sauce.id));
    setDeleting(false);
  }

  return (
    <div className="deleteSauceBackdrop">
      <div className="deleteSauceModale">
        <p>{`Êtes vous sûre de vouloir supprimer la sauce "${sauce.nom}" ?`}</p>
        <div>
          <Button
            variant="contained"
            size="small"
            onClick={() => setDeleting(false)}
          >
            Annuler
          </Button>
          <Button variant="contained" size="small" onClick={handleDelete}>
            Supprimer
          </Button>
        </div>
      </div>
    </div>
  );
}
