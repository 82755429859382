import React from "react";

function DragItem() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="800"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 10h14m-5 9l-2 2-2-2m4-14l-2-2-2 2m-5 9h14"
      ></path>
    </svg>
  );
}

export default DragItem;