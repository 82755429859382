import {
  CREATE_MENU,
  GET_MENUS,
  UPDATE_MENU,
  DELETE_MENU,
} from "../actions/menus.actions";

const initialState = {};

export default function menusReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_MENU:
      return action.payload;
    case GET_MENUS:
      return action.payload;
    case UPDATE_MENU:
      return state.map((menu) => {
        if (menu.id === action.payload.id) {
          return {
            ...menu,
            titre: action.payload.titre,
            description: action.payload.description,
            prix: action.payload.prix,
            top: action.payload.top,
            showed: action.payload.showed,
          };
        } else return menu;
      });
    case DELETE_MENU:
      return state.filter((menu) => menu.id !== action.payload.id);
    default:
      return state;
  }
}
