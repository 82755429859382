import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSauces } from "../actions/sauces.actions";
import { UidContext } from "./appContext";
import CreateSauce from "./sauces/CreateSauce";
import UpdateSauce from "./sauces/UpdateSauce";

export default function Sauces() {
  const [isLoading, setIsLoading] = useState(true);
  const uid = useContext(UidContext);
  const saucesData = useSelector((state) => state.saucesReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading) {
      dispatch(getSauces());
    }
    if (saucesData[0]) {
      setIsLoading(false);
    }
  }, [saucesData, isLoading, dispatch]);

  return (
    <>
      <div className="sauceBox">
        <h5>Sauce au choix</h5>
        <ul>
          {!isLoading && (
            <>
              {saucesData.map(function (sauce, index) {
                return (
                  <li key={index}>
                    <UpdateSauce sauce={sauce} />
                  </li>
                );
              })}
            </>
          )}
        </ul>
        {uid && <CreateSauce />}
      </div>
      <div className="tarifSauce">
        <p>Supplément sauce</p>
        <p>1 €</p>
      </div>
      <div className="tarifSauce">
        <p>Supplément garniture</p>
        <p>2 €</p>
      </div>
    </>
  );
}
