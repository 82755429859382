import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getMenus } from "../actions/menus.actions";
import api_url from "../Api";
import { UidContext } from "../components/appContext";
import CreateMenu from "../components/CreateMenu";
import Header from "../components/Header";
import Logout from "../components/Logout";
import Notice from "../components/Notice";
import Rubrique from "../components/Rubrique";

export default function Menu() {
  const [isLoading, setIsLoading] = useState(true);
  const [rubriques, setRubriques] = useState([]);

  // var rubriques = [];
  const dispatch = useDispatch();
  const uid = useContext(UidContext);

  useEffect(() => {
    axios
      .get(`${api_url}api/rubriques/`)
      .then((res) => {
        setRubriques(res.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  function handleChildCreate() {
    dispatch(getMenus());
  }

  return (
    <div className="appContainer">
      <Header />
      {uid && <Logout />}
      <div className="menuContainer">
        {!isLoading ? (
          <>
            <div className="wine drawer">
              <span>Demandez notre séléction de vins du mois</span>
            </div>
            {rubriques.map((rubrique, index) => (
              <Rubrique
                key={index}
                id={rubrique.id}
                rubrique={rubrique.name}
                menus={rubrique.menus}
                variant={rubrique.variant}
              />
            ))}
          </>
        ) : (
          <div className="loader">
            <CircularProgress color="primary" />
          </div>
        )}
        {!isLoading && uid && <CreateMenu parentCallback={handleChildCreate} />}
        {!isLoading && <Notice />}
      </div>
    </div>
  );
}
