import LogoutIcon from "@mui/icons-material/Logout";
import { IconButton } from "@mui/material";
import axios from "axios";
import cookie from "js-cookie";
import React from "react";
import api_url from "../Api";

export default function Logout() {
  const removeCookie = (key) => {
    if (window !== "undefined") {
      cookie.remove(key, { expires: 1 });
    }
  };

  const logout = async () => {
    await axios({
      method: "get",
      url: `${api_url}api/user/logout`,
      withCredentials: true,
    })
      .then(() => removeCookie("jwt"))
      .catch((err) => console.log(err));

    window.location = "/";
  };

  return (
    <IconButton aria-label="Déconnexion" className="logout" onClick={logout}>
      <LogoutIcon color="red" />
    </IconButton>
  );
}
