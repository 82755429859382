import { combineReducers } from "redux";
import userReducer from "./user.reducer";
import menusReducer from "./menus.reducer";
import saucesReducer from "./sauces.reducer";

export default combineReducers({
  userReducer,
  menusReducer,
  saucesReducer,
});
