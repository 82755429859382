import axios from "axios";
import api_url from "../Api";

// menus
export const CREATE_MENU = "CREATE_MENU";
export const GET_MENUS = "GET_MENUS";
export const UPDATE_MENU = "UPDATE_MENU";
export const DELETE_MENU = "DELETE_POST";
export const UPDATE_RUBRIQUE = "UPDATE_RUBRIQUE";

export const createMenu = (titre, description, prix, top, rubrique) => {
  return (dispatch) => {
    return axios
      .post(`${api_url}api/menus/createmenu`, {
        rubrique: rubrique,
        titre: titre,
        description: description,
        prix: prix,
        top: top,
      })
      .then((res) => {
        return axios
          .get(`${api_url}api/menus/`)
          .then((res) => {
            dispatch({ type: CREATE_MENU, payload: res.data.item });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };
};

export const getMenus = () => {
  return (dispatch) => {
    return axios
      .get(`${api_url}api/menus/`)
      .then((res) => {
        dispatch({ type: GET_MENUS, payload: res.data.item });
      })
      .catch((err) => console.log(err));
  };
};

export const updateMenu = (
  id,
  titre,
  description,
  prix,
  top,
  rubrique,
  showed
) => {
  return (dispatch) => {
    return axios
      .put(`${api_url}api/menus/${id}`, {
        titre: titre,
        description: description,
        prix: prix,
        top: top,
        rubrique: rubrique,
        showed: showed,
      })
      .then((res) => {
        dispatch({
          type: UPDATE_MENU,
          payload: {
            id,
            titre,
            description,
            prix,
            top,
            rubrique,
            showed,
          },
        });
      })
      .catch((err) => console.log(err));
  };
};

export const deleteMenu = (id) => {
  return (dispatch) => {
    return axios
      .delete(`${api_url}api/menus/${id}`)
      .then((res) => {
        dispatch({
          type: DELETE_MENU,
          payload: { id },
        });
      })
      .catch((err) => console.log(err));
  };
};

export const updateRubriqueOrder = (id, menuList) => {
  return (dispatch) => {
    return axios
      .patch(`${api_url}api/rubriques/organize/${id}`, {
        menuList : JSON.stringify(menuList)
      })
      .then((res) => {
        dispatch({
          type: UPDATE_RUBRIQUE,
          payload: {
            menuList
          },
        });
      })
      .catch((err) => console.log(err));
  };
};
