import React from "react";

export default function Notice() {
  return (
    <p className="noticeAllergenes">
      Allergènes : Gluten, Crustacés, Oeufs, Poisson, Arachide, Soja, Lait,
      Fruit à coque, Céléri, Moutarde, Sésame, Lupin, Mollusque, Sulfite, Blé,
      Orge.
    </p>
  );
}
