import axios from "axios";
import api_url from "../Api";

export const GET_USER = "GET_USER";

export default function getUser(uid) {
  return (dispatch) => {
    return axios
      .get(`${api_url}/api/user/${uid}`)
      .then((res) => {
        dispatch({ type: GET_USER, payload: res.data });
      })
      .catch((err) => console.log(err));
  };
}
