import { Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { deleteMenu } from "../actions/menus.actions";

export default function DeleteModal({ menu, handleAbortDelete }) {
  const dispatch = useDispatch();

  function handleDelete(e) {
    dispatch(deleteMenu(menu.id));
    handleAbortDelete();
  }

  return (
    <div className="deleteConfirmModal">
      <div className="deleteMessage">
        <p>{`Êtes vous sûre de vouloir supprimer "${menu.titre}" ?`}</p>
        <div>
          <Button variant="contained" size="small" onClick={handleAbortDelete}>
            Annuler
          </Button>
          <Button variant="contained" size="small" onClick={handleDelete}>
            Supprimer
          </Button>
        </div>
      </div>
    </div>
  );
}
