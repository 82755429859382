import React from "react";

export default function Header() {
  return (
    <div className="header">
      <img src={require("../assets/logo.png")} alt="Le brennus" />
      <h1>Le Menu</h1>
    </div>
  );
}
