import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import axios from "axios";
import React, { useState } from "react";
import api_url from "../Api";

export default function Login() {
  const [identity, setIdentity] = useState("");
  const [password, setPassword] = useState("");

  function handleLogin(e) {
    e.preventDefault();
    const identityError = document.getElementById("identityError");
    const passwordError = document.getElementById("passwordError");

    axios({
      method: "post",
      url: `${api_url}api/user/login`,
      withCredentials: true,
      data: {
        identity,
        password,
      },
    })
      .then((res) => {
        identityError.innerHTML = "";
        passwordError.innerHTML = "";
        if (res.data.identityError) {
          identityError.innerHTML = res.data.identityError;
        } else if (res.data.passwordError) {
          passwordError.innerHTML = res.data.passwordError;
        } else {
          window.location = "/";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="loginContainer">
      <div className="loginHeader">
        <img src={require("../assets/logo.png")} alt="Le brennus" />
      </div>
      <Box component="form" onSubmit={handleLogin}>
        <TextField
          className="loginInput"
          autoFocus
          id="identity"
          type="text"
          name="identity"
          label="Identifiant"
          fullWidth
          color="primary"
          onChange={(e) => setIdentity(e.target.value)}
          value={identity}
        />
        <div className="error" id="identityError"></div>
        <TextField
          className="loginInput"
          label="Mot de passe"
          fullWidth
          color="primary"
          type="password"
          name="password"
          id="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <div className="error" id="passwordError"></div>
        <Button color="primary" type="submit" variant="contained">
          Connexion
        </Button>
      </Box>
    </div>
  );
}
