import EditIcon from "@mui/icons-material/Edit";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateMenu } from "../actions/menus.actions";

export default function UpdateMenu({ menu, parentCallback }) {
  const [titleUpdated, setTitleUpdated] = useState(menu.titre);
  const [priceUpdated, setPriceUpdated] = useState(menu.prix);
  const [contentUpdated, setContentUpdated] = useState(menu.description);
  const [topUpdated, setTopUpdated] = useState(menu.top);
  // const [rubriqueUpdated, setRubriqueUpdated] = useState(menu.rubrique);
  const dispatch = useDispatch();

  function handleUpdate(e) {
    dispatch(
      updateMenu(
        menu.id,
        titleUpdated,
        contentUpdated,
        priceUpdated,
        topUpdated
        // rubriqueUpdated
      )
    );
    parentCallback(false);
  }

  return (
    <div className="updateInfos">
      <div>
        <TextField
          id="standard-basic"
          label="Titre"
          color="primary"
          variant="standard"
          defaultValue={menu.titre}
          onChange={(e) => setTitleUpdated(e.target.value)}
          focused
        />
        <TextField
          id="standard-basic"
          label="Prix"
          variant="standard"
          color="primary"
          type="number"
          // endAdornment={<InputAdornment position="end">€</InputAdornment>}
          defaultValue={parseFloat(menu.prix).toFixed(2)}
          onChange={(e) => setPriceUpdated(e.target.value)}
          focused
        />
      </div>
      <div>
        <TextField
          id="standard-basic"
          label="Description"
          color="primary"
          variant="standard"
          multiline
          rows={4}
          defaultValue={menu.description}
          focused
          onChange={(e) => setContentUpdated(e.target.value)}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              sx={{
                color: "#be722f",
                "&.Mui-checked": {
                  color: "#be722f",
                },
              }}
              defaultChecked={menu.top}
              onClick={(e) => setTopUpdated(e.target.checked)}
            />
          }
          label="Coup de coeur"
        />
      </div>
      {/* <div>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={menu.rubrique
            .split(" ")
            .map(function (word) {
              return word[0].toUpperCase() + word.substr(1);
            })
            .join(" ")}
          label="Catégorie" 
          onChange={(e) => setRubriqueUpdated(e.target.value)}
        >
          {rubriquesList.map((rubrique) => {
            return (
              <MenuItem
                value={rubrique
                  .split(" ")
                  .map(function (word) {
                    return word[0].toUpperCase() + word.substr(1);
                  })
                  .join(" ")}
              >
                {rubrique
                  .split(" ")
                  .map(function (word) {
                    return word[0].toUpperCase() + word.substr(1);
                  })
                  .join(" ")}
              </MenuItem>
            );
          })}
        </Select>
      </div> */}
      <div>
        <Button
          size="small"
          variant="contained"
          color="primary"
          endIcon={<EditIcon />}
          onClick={handleUpdate}
          sx={{
            fontSize: 10,
          }}
        >
          Valider
        </Button>
      </div>
    </div>
  );
}
