import React, { useContext, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { UidContext } from "../appContext";
import { Button, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { updateSauce } from "../../actions/sauces.actions";
import DeleteSauce from "./DeleteSauce";

export default function UpdateSauce({ sauce }) {
  const dispatch = useDispatch();
  const uid = useContext(UidContext);
  const [updating, setUpdating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [nameUpdated, setNameUpdated] = useState(sauce.nom);

  function handleUpdate(e) {
    dispatch(updateSauce(sauce.id, nameUpdated));
    setUpdating(false);
  }

  return (
    <>
      {deleting && <DeleteSauce sauce={sauce} setDeleting={setDeleting} />}
      {updating ? (
        <TextField
          id="standard-basic"
          label="Nom"
          variant="standard"
          color="primary"
          defaultValue={sauce.nom}
          className="updateInput"
          onChange={(e) => setNameUpdated(e.target.value)}
          focused
        />
      ) : (
        <p>*{sauce.nom}</p>
      )}
      {uid && (
        <div className="adminActionSauce">
          {!updating ? (
            <>
              <Button
                onClick={() => setUpdating(true)}
                size="small"
                variant="contained"
                color="primary"
                endIcon={<EditIcon />}
                sx={{
                  fontSize: 10,
                }}
              >
                Modifier
              </Button>
              <Button
                onClick={() => setDeleting(true)}
                size="small"
                variant="contained"
                color="error"
                endIcon={<DeleteIcon />}
                sx={{
                  fontSize: 10,
                }}
              >
                Supprimer
              </Button>
            </>
          ) : (
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={handleUpdate}
              endIcon={<EditIcon />}
              sx={{
                fontSize: 10,
              }}
            >
              Valider
            </Button>
          )}
        </div>
      )}
    </>
  );
}
