import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Button, IconButton } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import React, { useContext, useEffect, useId, useState } from "react";
import { useDispatch } from "react-redux";
import { updateMenu } from "../actions/menus.actions";
import { UidContext } from "./appContext";
import DeleteModal from "./DeleteModal";
import UpdateMenu from "./UpdateMenu";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DragItem from "./DragItem";

export default function Menu({ menu }) {
  const [updating, setUpdating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showed, setShowed] = useState(menu.showed);
  const dispatch = useDispatch();
  const uid = useContext(UidContext);
  const id = useId();  

  function handleCallback(childData) {
    setUpdating(childData);
  }

  useEffect(() => {
    setShowed(menu.showed);
  }, [menu])

  // masquage du menu
  function handleShow() {
    dispatch(
      updateMenu(
        menu.id,
        menu.titre,
        menu.description,
        menu.prix,
        menu.top,
        !showed
      )
    );
    setShowed(!showed);
  }

  function handleAbortDelete(childData) {
    setDeleting(false);
  }

  // On déclare ce dont on a besoin pour détecter le mouvement de notre item à trier
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    isSorting
  } = useSortable({ id: menu.id });

  const style = {
    transform: transform ? CSS.Transform.toString(transform) : '',
    transition: isDragging || isSorting ? transition : '',
    position: 'relative'
  }

  return (
    <div
      ref={ setNodeRef }
      className={
        "menuBox" +
        (!showed && !uid ? " hiddenMenuBox" : "") +
        (!showed && uid ? " greyMenuBox" : "")
      }
      style={{...style}}
    >
      {uid && <div className="dragItem"{ ...listeners } { ...attributes }>
        <DragItem/>
      </div> }
      {deleting && (
        <DeleteModal
          key={"frvdsgrsg" + id}
          menu={menu}
          handleAbortDelete={handleAbortDelete}
        />
      )}
      <AccordionDetails
        key={"frfrehgtehbdvdsgrsg" + id}
        className="detailsDrawer"
      >
        <div className="descriptionDrawer" variant={"body2"}>
          {!updating ? (
            <>
              <div className="infoMenu">
                <div className="titleAndDetails">
                  <span>{menu.titre}</span>
                  {menu.top ? (
                    <span className="top">«Coup de coeur»</span>
                  ) : (
                    <></>
                  )}
                  {menu.description && <p>{menu.description}</p>}
                </div>
                <div className="price">
                  <span>
                    {parseFloat(menu.prix).toFixed(2).replace(".", ",")} €
                  </span>
                </div>
              </div>
            </>
          ) : (
            <UpdateMenu menu={menu} parentCallback={handleCallback} />
          )}
        </div>
      </AccordionDetails>
      {uid && !updating && (
        <div className="adminActionBar">
          <Button
            onClick={() => setUpdating(true)}
            size="small"
            variant="contained"
            color="primary"
            endIcon={<EditIcon />}
            sx={{
              fontSize: 10,
              marginRight: 1,
            }}
          >
            Modifier
          </Button>
          <Button
            onClick={() => setDeleting(true)}
            size="small"
            variant="contained"
            color="error"
            endIcon={<DeleteIcon />}
            sx={{
              fontSize: 10,
            }}
          >
            Supprimer
          </Button>
          <IconButton
            aria-label="add an alarm"
            color="primary"
            sx={{
              marginLeft: 1,
            }}
            onClick={handleShow}
          >
            {!showed ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </div>
      )}
    </div>
  );
}
