import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Menu from "../pages/Menu";
import Error from "../pages/Error";
import Login from "../pages/Login";

export default function index() {
  return (
    <Router>
      <Routes>
        {/* Lecture du menu */}
        <Route path="/" element={<Menu />} />
        <Route path="/menu" element={<Menu />} />
        {/* Administration */}
        <Route path="/admin" element={<Login />} />
        {/* Page 404 */}
        <Route path="*" element={<Error />} />
      </Routes>
    </Router>
  );
}
