import "./styles/index.scss";
import Routes from "./routes";
import { UidContext } from "./components/appContext";
import { useEffect, useState } from "react";
import api_url from "./Api";
import axios from "axios";
import { createTheme, /*IconButton,*/ ThemeProvider } from "@mui/material";
// import Brightness4Icon from "@mui/icons-material/Brightness4";
// import Brightness7Icon from "@mui/icons-material/Brightness7";

function App() {
  // const [darkMode, setDarkMode] = useState(true);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#be722f",
      },
    },
  });
  const [uid, setUid] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      await axios({
        method: "get",
        url: `${api_url}jwtid`,
        withCredentials: true,
      })
        .then((res) => setUid(res.data[0]))
        .catch((err) => console.log(err));
    };
    fetchToken();
  }, [uid]);

  return (
    <ThemeProvider theme={theme}>
      <UidContext.Provider value={uid}>
        {/* <IconButton
          sx={{ position: "absolute", right: 0 }}
          onClick={() => setDarkMode(!darkMode)}
          color="inherit"
        >
          {theme.palette.mode ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton> */}
        <Routes />
      </UidContext.Provider>
    </ThemeProvider>
  );
}

export default App;
