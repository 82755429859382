const rubriquesList = [
  "Les Formules du jour",
  "Les Planches Apéritives",
  "Les Salades",
  "Les Pizzas",
  "Les Poissons",
  "Les Pâtes Fraîches",
  "Les Planchas",
  "Les Classiques Brasserie",
  "Les Desserts",
  "Les Desserts hors Formule",
];

export default rubriquesList;
