import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createMenu } from "../actions/menus.actions";
import rubriquesList from "../Utils";

export default function CreateMenu({ parentCallback }) {
  const [creating, setCreating] = useState(false);
  const [titleCreated, setTitleCreated] = useState(null);
  const [priceCreated, setPriceCreated] = useState(null);
  const [contentCreated, setContentCreated] = useState(null);
  const [topCreated, setTopCreated] = useState(false);
  const [rubriqueCreated, setRubriqueCreated] = useState(rubriquesList[0]);
  const dispatch = useDispatch();

  function handleCreate() {
    if (titleCreated && priceCreated && rubriqueCreated) {
      dispatch(
        createMenu(
          titleCreated,
          contentCreated,
          priceCreated,
          topCreated,
          rubriqueCreated
        )
      );
      setCreating(false);
      document.getElementById("title").value = null;
      document.getElementById("content").value = null;
      document.getElementById("price").value = null;
      setTitleCreated(null);
      setPriceCreated(null);
      setContentCreated(null);
      setTopCreated(false);
      parentCallback();
    } else {
      window.alert("Veuillez remplir les champs obligatoires");
    }
  }

  return (
    <Accordion className="createMenuAccordion drawer" expanded={creating}>
      <AccordionSummary
        onClick={() => setCreating(!creating)}
        className="createMenuSummary"
        expandIcon={<AddCircleOutlineIcon className="addIcon" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>Ajouter un nouveau plat</Typography>
      </AccordionSummary>
      <AccordionDetails className="createMenuDetails">
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              className="createMenuField"
              required
              id="title"
              label="Titre"
              variant="standard"
              color="primary"
              fullWidth
              onChange={(e) => setTitleCreated(e.target.value)}
            />
            <TextField
              className="createMenuField"
              required
              id="content"
              label="Description"
              variant="standard"
              color="primary"
              fullWidth
              onChange={(e) => setContentCreated(e.target.value)}
            />
            <TextField
              className="createMenuField"
              required
              id="price"
              label="Prix"
              variant="standard"
              color="primary"
              type="number"
              fullWidth
              onChange={(e) => setPriceCreated(e.target.value)}
            />
            <FormControlLabel
              className="createMenuField"
              control={
                <Checkbox
                  sx={{
                    color: "#be722f",
                    "&.Mui-checked": {
                      color: "#be722f",
                    },
                  }}
                  onClick={(e) => setTopCreated(e.target.checked)}
                />
              }
              label="Coup de coeur"
            />
            <Select
              className="createMenuField"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label=""
              color="primary"
              onChange={(e) => setRubriqueCreated(e.target.value)}
              defaultValue={rubriquesList[0]}
            >
              {rubriquesList.map((rubrique, index) => {
                return (
                  <MenuItem key={index} value={rubrique}>
                    {rubrique}
                  </MenuItem>
                );
              })}
            </Select>
            <Button
              size="small"
              variant="contained"
              color="primary"
              endIcon={<AddCircleOutlineIcon />}
              onClick={handleCreate}
              sx={{
                fontSize: 10,
                marginTop: 3,
              }}
            >
              Ajouter
            </Button>
          </div>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
